@import 'variables';

// Export values without units: 10px / 1px = 10
:export {
  mobileWidth: $mobileWidth / 1px;
  mobileHeight: $mobileHeight / 1px;
  desktopWidth: $desktopWidth / 1px;
  desktopHeight: $desktopHeight / 1px;
}

// ClassName here is E_PREVIEW_MODE value
.desktop,
.mobile {
  position: relative;
  transform: scale(var(--preview-scale));
  transform-origin: 0 0;

  .frame-holder {
    overflow: hidden;
    position: relative;
  }

  // Reduces blurriness on non-Retina displays: 
  // scales down all content, then scales it back up for clarity
  .screen {
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  .screen-holder {
    transform: scale(2);
    transform-origin: 0 0;
  }

  :global(iframe) {
    display: block;
    width: 100%;
    border: none;
    background: #fff;
    image-rendering: optimizeQuality;
  }
}

.mobile {
  width: $mobileWidth;
  height: $mobileHeight;
  padding: 17px 13px;

  // left buttons
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 70px;
    top: 140px;
    left: 7px;
    box-shadow: 0 0 0 3px var(--device-frame-outer-border) inset;
  }

  // left top button
  &::after {
    top: 220px;
  }

  .frame {
    padding: 78px 9px 52px;
    background: var(--device-frame-inner-background);
    border: 3px solid var(--device-frame-outer-border);
    border-radius: 58px;
    box-shadow: 0 0 0 6px var(--device-frame-outer-background) inset, 0 0 0 9px var(--device-frame-outer-border) inset;

    // right button
    &::before {
      content: '';
      position: absolute;
      width: 9px;
      height: 96px;
      top: 160px;
      right: 7px;
      box-shadow: 0 0 0 3px var(--device-frame-outer-border) inset;
    }

    // url bar
    &::after {
      content: '';
      position: absolute;
      border-radius: 16px;
      height: 28px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 64px;
      width: 86px;
      z-index: 1;
      background: var(--device-frame-inner-details);
      box-shadow: 0 -32px 0 -4px var(--device-frame-outer-background), // dynamic island - inner
        // 0 -32px 0 -2px var(--device-frame-outer-border), // dynamic island - outer border
        -60px 0 var(--device-frame-inner-details), -106px 0 var(--device-frame-inner-details), // url bar - left
        60px 0 var(--device-frame-inner-details), 106px 0 var(--device-frame-inner-details), // url bar - right
        0 596px 0 -12px var(--device-frame-inner-details), -30px 596px 0 -12px var(--device-frame-inner-details), 30px 596px 0 -12px var(--device-frame-inner-details); // bottom bar
    }
  }

  // TODO: change to 390x844 - most popular screen size (iPhone 12/13/14) for 2022/2023
  :global(iframe) {
    width: 320px;
    height: 564px;
  }
}

.desktop {
  width: $desktopWidth;
  height: $desktopHeight;
  padding: 43px 31px 130px;

  .frame {
    border-radius: 75px;
    padding: 70px 0 0;
    overflow: hidden;
    background: var(--device-frame-inner-background);
    background: radial-gradient(circle at 60px 35px, var(--device-frame-inner-details) 8px, transparent 9px),
      radial-gradient(circle at 90px 35px, var(--device-frame-inner-details) 8px, transparent 9px),
      radial-gradient(circle at 120px 35px, var(--device-frame-inner-details) 8px, transparent 9px),
      var(--device-frame-inner-background);
    border: 5px solid var(--device-frame-outer-border);
    box-shadow: 0 0 0 9px var(--device-frame-outer-background), 0 0 0 14px var(--device-frame-outer-border);

    // url bar
    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 20px;
      width: 950px;
      height: 40px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 64px;
      z-index: 1;
      background: var(--device-frame-inner-details);
    }

    // stand
    &::after {
      top: auto;
      bottom: 0;
      width: 1200px;
    }
  }

  // Special fix for Safari v14-v16. Remove it in 2026
  .screen {
    position: relative;
    border-radius: 0 0 70px 70px;
  }

  :global(iframe) {
    width: 1900px;
    height: 1080px;
  }
}

:global(.center) {
  & .desktop,
  & .mobile {
    top: 50%;
    left: 50%;
    transform: scale(var(--preview-scale)) translate(-50%, -50%);
    animation: showScreen 0.2s ease-in;
  }
}

@keyframes showScreen {
  0% {
    transform: scale(var(--preview-scale)) translate(-50%, -50%);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// TODO: do we need it?
.large-text {
  font-size: 160px;
}
